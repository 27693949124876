import {
    AccordionBody,
    AccordionHeader,
    Badge,
    Button,
    Label,
    ModalBody,
    ModalFooter,
    UncontrolledAccordion
} from "reactstrap";
import moment from "moment";
import {useState} from "react";
import PDFTemplateDetail from "./PDFTemplateDetail";

const Detail = ({setActive, data}) => {
    const [selectedSalaries, setSelectedSalaries] = useState([])
    const [selectedSalaries2, setSelectedSalaries2] = useState([])
    const handleChangeCheckbox = (salary) => {
        if (selectedSalaries.map(item => item.id).includes(salary.id)) {
            return setSelectedSalaries(prev => ([...prev.filter((item => item.id !== salary.id))]))
        }
        setSelectedSalaries(prev => ([...prev, salary]))
    }

    const toggleAllData = () => {
        if (data.data?.doctor_examinations?.length === selectedSalaries.length) {
            return setSelectedSalaries([])
        }
        setSelectedSalaries(data.data?.doctor_examinations)
    }

    const handleChangeCheckbox2 = (salary) => {
        if (selectedSalaries2.map(item => item.id).includes(salary.id)) {
            return setSelectedSalaries2(prev => ([...prev.filter((item => item.id !== salary.id))]))
        }
        setSelectedSalaries2(prev => ([...prev, salary]))
    }

    const toggleAllData2 = () => {
        if (data.data?.worker_examinations?.length === selectedSalaries.length) {
            return setSelectedSalaries2([])
        }
        setSelectedSalaries2(data.data?.worker_examinations)
    }

    return (
        <>
            <ModalBody>
                <UncontrolledAccordion>
                    <AccordionHeader targetId="doctor" className="bg-light border rounded-1 rounded-bottom-0">
                        <p className="fw-bold mb-0">Müayinədən gələn gəlirlər</p>
                    </AccordionHeader>
                    <AccordionBody accordionId="doctor">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>№
                                        <Label className="d-flex gap-1">
                                            <div className="form-check">
                                                <input type="checkbox"
                                                       checked={selectedSalaries.length === data?.data?.doctor_examinations?.length}
                                                       onChange={toggleAllData}
                                                       disabled={!data?.data?.doctor_examinations.length}
                                                       className="form-check-input"/>
                                            </div>
                                        </Label>
                                    </th>
                                    <th>Pasient</th>
                                    <th>Həkim</th>
                                    <th>Göndərən şəxs</th>
                                    <th>Xidmət</th>
                                    <th>Ödənilən məbləğ</th>
                                    <th>Ödənilməli olan məbləğ</th>
                                    <th>Tarix</th>
                                    <th>Qeyd</th>
                                    <th>
                                        <PDFTemplateDetail salaries={selectedSalaries}/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.data?.doctor_examinations?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}
                                            <Label className="d-flex gap-1">
                                                <div className="form-check">
                                                    <input type="checkbox"
                                                           value={1}
                                                           checked={selectedSalaries?.map(x => x.id).includes(item.id)}
                                                           onChange={() => {
                                                               handleChangeCheckbox(item)
                                                           }}
                                                           name={`checkbox-${item.id}`}
                                                           className="form-check-input"/>
                                                </div>
                                            </Label>
                                        </td>
                                        <td>{item?.patient}</td>
                                        <td>{item?.doctor}</td>
                                        <td>{item?.worker}</td>
                                        <td>{item?.service} - {item?.payments_sum_amount} AZN</td>
                                        <td>
                                            <Badge color="success">
                                                {item?.payments_sum_doctor_paid?.toFixed(2)}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Badge color="danger">
                                                {item?.payments_sum_doctor_taken?.toFixed(2)}
                                            </Badge>
                                        </td>
                                        <td>{item?.admission_date && moment(item?.admission_date).format('DD.MM.YYYY')}</td>
                                        <td>{item?.note}</td>
                                        <td>
                                            <PDFTemplateDetail item={item}/>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td>
                                        <b>Cəm</b>
                                    </td>
                                    <td>
                                        <Badge color="success">
                                            {data?.data?.doctor_examinations?.reduce((acc, val) => {
                                                return (Number(acc) + Number(val?.payments_sum_doctor_paid))?.toFixed(2)
                                            }, 0)}
                                        </Badge>
                                    </td>
                                    <td>
                                        <Badge color="danger">
                                            {data?.data?.doctor_examinations?.reduce((acc, val) => {
                                                return (Number(acc) + Number(val?.payments_sum_doctor_taken))?.toFixed(2)
                                            }, 0)}
                                        </Badge>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </AccordionBody>
                </UncontrolledAccordion>
                <UncontrolledAccordion>
                    <AccordionHeader targetId="doctor" className="bg-light border rounded-1 rounded-top-0">
                        <p className="fw-bold mb-0">Göndərişdən gələn gəlirlər</p>
                    </AccordionHeader>
                    <AccordionBody accordionId="doctor">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>№
                                        <Label className="d-flex gap-1">
                                            <div className="form-check">
                                                <input type="checkbox"
                                                       checked={selectedSalaries2.length === data?.data?.worker_examinations?.length}
                                                       onChange={toggleAllData2}
                                                       disabled={!data?.data?.worker_examinations.length}
                                                       className="form-check-input"/>
                                            </div>
                                        </Label>
                                    </th>
                                    <th>Pasient</th>
                                    <th>Həkim</th>
                                    <th>Göndərən şəxs</th>
                                    <th>Xidmət</th>
                                    <th>Ödənilən məbləğ</th>
                                    <th>Ödənilməli olan məbləğ</th>
                                    <th>Tarix</th>
                                    <th>Qeyd</th>
                                    <th>
                                        <PDFTemplateDetail salaries={selectedSalaries2}/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.data?.worker_examinations?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1} <Label className="d-flex gap-1">
                                            <div className="form-check">
                                                <input type="checkbox"
                                                       value={1}
                                                       checked={selectedSalaries2?.map(x => x.id).includes(item.id)}
                                                       onChange={() => {
                                                           handleChangeCheckbox2(item)
                                                       }}
                                                       name={`checkbox-${item.id}`}
                                                       className="form-check-input"/>
                                            </div>
                                        </Label></td>
                                        <td>{item?.patient}</td>
                                        <td>{item?.doctor}</td>
                                        <td>{item?.worker}</td>
                                        <td>{item?.service} - {item?.payments_sum_amount} AZN</td>
                                        <td>
                                            <Badge color="success">
                                                {item?.payments_sum_worker_paid}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Badge color="danger">
                                                {item?.payments_sum_worker_taken}
                                            </Badge>
                                        </td>
                                        <td>{item?.admission_date && moment(item?.admission_date).format('DD.MM.YYYY')}</td>
                                        <td>{item?.note}</td>
                                        <td>
                                            <PDFTemplateDetail item={item}/>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td>
                                        <b>Cəm</b>
                                    </td>
                                    <td>
                                        <Badge color="success">
                                            {data?.data?.worker_examinations?.reduce((acc, val) => {
                                                return (Number(acc) + Number(val?.payments_sum_worker_paid))?.toFixed(2)
                                            }, 0)}
                                        </Badge>
                                    </td>
                                    <td>
                                        <Badge color="danger">
                                            {data?.data?.worker_examinations?.reduce((acc, val) => {
                                                return (Number(acc) + Number(val?.payments_sum_worker_taken))?.toFixed(2)
                                            }, 0)}
                                        </Badge>
                                    </td>
                                    <td/>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </AccordionBody>
                </UncontrolledAccordion>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setActive({})} color="secondary">Bağla</Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default Detail
