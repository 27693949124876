import ReactPdf from "@react-pdf/renderer";
import moment from "moment/moment";
import Logo from '../../assets/images/sidebar/logo.png'
import RegularDejavu from '../Cashbox/DejaVuSans.ttf'
import BoldDejavu from '../Cashbox/DejaVuSans-Bold.ttf'
import ItalicDejavu from '../Cashbox/DejaVuSerif-Italic.ttf'

const PDFTemplate = ({item, salaries, startDate, endDate}) => {
    let data = []
    if (salaries?.length) {
        data = salaries
    }
    if (item) {
        data = [item]
    }

    ReactPdf.Font.register({
        family: "DejaVu Sans",
        fonts: [
            {src: RegularDejavu, fontWeight: 'normal'},
            {src: BoldDejavu, fontWeight: 'bold'},
            {src: ItalicDejavu, fontStyle: 'italic'},
        ]
    });

    const styles = ReactPdf.StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
            fontFamily: 'DejaVu Sans',
            fontSize: 16,
            paddingLeft: 16,
            paddingRight: 16
        },
        header: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        bold: {
            fontWeight: 'bold'
        },
        section: {
            margin: 10,
            padding: 10,
        },
        textCenter: {
            textAlign: "center"
        },
        justifyBetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: 10,
            marginRight: 10,
            marginTop: 4,
            fontSize: 14,
        },
        divider: {
            height: 1,
            backgroundColor: '#000',
            width: '100%'
        },
        dividerText: {
            fontSize: 8,
            fontStyle: 'italic'
        },
        footer: {
            fontSize: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        table: {
            marginTop: 8,
            border: 1,
            borderColor: '#000',
        },
        tableHeaderRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#eff2ff',
        },
        tableHeaderCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 8,
            width: '24%',
            fontWeight: 'bold',
            borderRight: '1px solid black',
            borderBottom: '1px solid black'
        },
        tableRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        tableCell: {
            paddingVertical: 2,
            textAlign: 'center',
            fontSize: 7,
            width: '24%',
            borderRight: '1px solid black',
        }
    });

    return (
        <ReactPdf.BlobProvider document={<ReactPdf.Document>
            <ReactPdf.Page size="A4" style={styles.page}>
                <ReactPdf.View style={styles.section}>
                    <ReactPdf.Text style={styles.header}>
                        <ReactPdf.Image style={{
                            width: 200,
                            height: 30,
                            marginLeft: 80
                        }} src={Logo}/>
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.footer}>
                    <ReactPdf.Text style={styles.dividerText}>
                        Bakı şəh, Bakıxanov qəs, S.Mehmandarov 8. {'\n'} Tel:+994 99 425 11 99 , +994 70 525 11 99,
                        +994 12 425 64 94, +994 12 425 11 99.
                    </ReactPdf.Text>
                    <ReactPdf.Text style={styles.dividerText}>
                        {moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.divider}/>
                <ReactPdf.View style={{flexDirection: 'column', fontSize: 12}}>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{fontWeight: 'bold'}}>
                            Başlama tarixi :{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{fontStyle: 'italic'}}>
                            {moment(new Date(startDate)).format('DD.MM.YYYY')}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                    <ReactPdf.Text>
                        <ReactPdf.Text style={{fontWeight: 'bold'}}>
                            Bitmə tarixi :{' '}
                        </ReactPdf.Text>
                        <ReactPdf.Text style={{fontStyle: 'italic'}}>
                            {moment(endDate).format('DD.MM.YYYY')}
                        </ReactPdf.Text>
                    </ReactPdf.Text>
                </ReactPdf.View>
                <ReactPdf.View style={styles.table}>
                    <ReactPdf.View style={styles.tableHeaderRow}>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: '4%'}]}>№</ReactPdf.Text>
                        <ReactPdf.Text style={styles.tableHeaderCell}>Ad soyad</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Pasient qəbul sayı</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Pasient qəbuluna görə qazanılan
                            gəlir</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Pasient qəbuluna görə ödənilən
                            maaş</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Göndərilən pasient
                            sayı</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Göndərilən pasientə görə qazanılan
                            gəlir</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Göndərilən pasientə görə ödənilən
                            maaş</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Ümumi qazanılan
                            məbləğ</ReactPdf.Text>
                        <ReactPdf.Text style={[styles.tableHeaderCell, {width: 100}]}>Ümumi ödənilən
                            məbləğ</ReactPdf.Text>
                    </ReactPdf.View>
                    {data.map((patient, index) => (
                        <ReactPdf.View
                            style={[styles.tableRow, {borderBottom: `${data.length === 1 ? 'none' : '1px solid #000'}`}]}
                            key={patient?.id}>
                            <ReactPdf.Text style={[styles.tableCell, {width: '4%'}]}>{index + 1}</ReactPdf.Text>
                            <ReactPdf.Text style={styles.tableCell}>{patient?.name} {patient?.surname}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.doctor_examination_count}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.doctor_examination_price?.toFixed(2)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.doctor_examination_paid_price?.toFixed(2)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.worker_examination_count}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.worker_examination_price?.toFixed(2)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{patient?.worker_examination_paid_price?.toFixed(2)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{(patient?.doctor_examination_price + patient?.worker_examination_price)?.toFixed(2)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{(patient?.doctor_examination_paid_price + patient?.worker_examination_paid_price)?.toFixed(2)}</ReactPdf.Text>
                        </ReactPdf.View>
                    ))}
                    {data.length > 1 && (
                        <ReactPdf.View
                            style={[styles.tableRow]}>
                            <ReactPdf.Text style={[styles.tableCell, {width: '4%'}]}/>
                            <ReactPdf.Text style={[styles.tableCell, {fontWeight: 'bold'}]}>Cəm</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>
                                {data?.reduce((acc, val) => {
                                    return (Number(acc) + Number(val?.doctor_examination_count))
                                }, 0)}
                            </ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.doctor_examination_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.doctor_examination_paid_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.worker_examination_count))
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.worker_examination_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.worker_examination_paid_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.doctor_examination_price) + Number(val?.worker_examination_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                            <ReactPdf.Text
                                style={[styles.tableCell, {width: 100}]}>{data?.reduce((acc, val) => {
                                return (Number(acc) + Number(val?.doctor_examination_paid_price) + Number(val?.worker_examination_paid_price))?.toFixed(2)
                            }, 0)}</ReactPdf.Text>
                        </ReactPdf.View>
                    )}
                </ReactPdf.View>
            </ReactPdf.Page>
        </ReactPdf.Document>}>
            {({url}) => {
                return <a className={`btn btn-primary ${!item && !salaries?.length && 'disabled'}`}
                          href={url}
                          target="_blank">
                    <i className="bx bx-printer"/>
                </a>
            }}
        </ReactPdf.BlobProvider>
    )
}

export default PDFTemplate
