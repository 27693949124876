import LocalStorageManager from "../../helpers/localStorageManager";

const Can = ({action, children}) => {
    const actions = LocalStorageManager.get('user')?.can
    if (Array.isArray(action)) {
        return action?.some(item => actions?.[item]) ? children : null

    } else if (!Array.isArray(action) && actions?.[action]) {
        return children
    }
    return null
}

export default Can
