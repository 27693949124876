import ConfirmModal from "components/Common/ConfirmModal";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useEffect, useState } from "react";
import Api from 'api/cashbox2';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Label,
    Modal,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import Add from "./Add";
import CustomPagination from "../../components/CustomPagination";
import Can from "../../components/Common/Can";
import Form from "../../helpers/form";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Employees from "../../api/employees";
import moment from "moment";
import FlatPicker from "react-flatpickr";
const Branches = () => {
    document.title = 'Giriş/Çıxış - Kassa';
    const PAGE_LIMIT = 500; // Page limit set to 500
    const [confirmModal, setConfirmModal] = useState(false);
    const [form, setForm] = useState({});
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const { control, getValues, handleSubmit, errors, reset } = useForm({
        defaultValues: {
            start_date: new Date(),
            end_date: new Date()
        }
    })
    const [workers, setWorkers] = useState([]);

    const deleteData = async () => {
        await Api.delete(confirmModal);
        fetchData();
    };

    const fetchData = async (showLoader = true, p = null) => {
        setIsFetching(showLoader);
        const data = await Api.get({
            page: p || page,
            limit: PAGE_LIMIT,
            ...Form.validateBody(getValues()),
            date: Form.convertFormDate(getValues()?.date),
            start_date: Form.convertFormDate(getValues()?.start_date),
            end_date: Form.convertFormDate(getValues()?.end_date)
        });
        setData(data?.data?.cashboxes);
        setTotal(data?.meta?.total);
        setIsFetching(false);
    };

    const types = [
        { value: 1, label: 'Giriş' },
        { value: 2, label: 'Çıxış' },
    ];

    const filter = () => {
        fetchData(true, 1);
    };

    const fetchWorkers = async () => {
        const { data } = await Employees.getSelect();
        setWorkers(data);
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page]);

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData} />
            <Modal className="modal-dialog-centered" isOpen={form?.status}
                toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>{form?.data ? 'Düzəliş et' : 'Əlavə et'}</ModalHeader>
                <Add types={types} fetchData={fetchData} form={form} setForm={setForm} />
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`GİRİŞ/ÇIXIŞ - KASSA (${total})`} />
                <Row>
                    <Col sm={12}>
                        <form onSubmit={handleSubmit(filter)}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <div className="mb-3">
                                                <Label for="worker_id">İşçi</Label>
                                                <Controller name="worker_id"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <Select
                                                            isClearable={true}
                                                            options={workers}
                                                            placeholder=""
                                                            className={`w-100 ${errors?.worker_id && 'is-invalid'}`}
                                                            onChange={onChange}
                                                            value={value}
                                                            name="worker_id"
                                                            id="worker_id" />
                                                    )} />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <div className="mb-3">
                                                <Label for="type">Növ</Label>
                                                <Controller name="type"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <Select
                                                            isClearable={true}
                                                            options={types}
                                                            placeholder=""
                                                            className={`w-100 ${errors?.type && 'is-invalid'}`}
                                                            onChange={onChange}
                                                            value={value}
                                                            name="type"
                                                            id="type" />
                                                    )} />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <div className="mb-3">
                                                <Label for="start_date">Başlama tarixi</Label>
                                                <Controller name="start_date"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <FlatPicker
                                                            className="form-control d-block"
                                                            value={value}
                                                            onChange={onChange}
                                                            options={{
                                                                locale: 'az'
                                                            }}
                                                        />
                                                    )} />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <div className="mb-3">
                                                <Label for="end_date">Bitmə tarixi</Label>
                                                <Controller name="end_date"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <FlatPicker
                                                            className="form-control d-block"
                                                            value={value}
                                                            onChange={onChange}
                                                            options={{
                                                                locale: 'az'
                                                            }}
                                                        />
                                                    )} />
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <Button id="reset-btn" color="primary" outline onClick={() => {
                                                    reset({
                                                        worker_id: null,
                                                        type: null,
                                                        end_date: new Date(),
                                                        start_date: getDefaultStartDate()
                                                    });
                                                    setPage(1);
                                                    fetchData(true, 1);
                                                }}>
                                                    <i className="bx bx-rotate-right" />
                                                </Button>
                                                <UncontrolledTooltip placement="bottom" target="reset-btn">
                                                    Sıfırla
                                                </UncontrolledTooltip>
                                                <Button color="primary" type="submit">Axtar</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </form>
                    </Col>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg" />
                                </div>
                            ) : (
                                <CardBody>
                                    <Can action="cashbox_add">
                                        <Button
                                            onClick={() => setForm({ status: true })}
                                            type="button"
                                            color="success"
                                            className="btn-label mb-3"
                                        >
                                            <i className="bx bx-plus label-icon" /> Əlavə et
                                        </Button>
                                    </Can>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>№</th>
                                                    <th>İşçi</th>
                                                    <th>Giriş</th>
                                                    <th>Çıxış</th>
                                                    <th>Növ</th>
                                                    <th>Tarix</th>
                                                    <th>Qeyd</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.worker_name}</td>
                                                        <td>{item.price > 0 ? item.price : ''}</td>
                                                        <td>{item.price < 0 ? item.price : ''}</td>
                                                        <td>{item.type === 1 ? 'Giriş' : 'Çıxış'}</td>
                                                        <td>{moment(item?.created_at).format('DD.MM.YYYY')}</td>
                                                        <td>{item.note}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <Can action="cashbox_edit">
                                                                    <>
                                                                        <Button color="success"
                                                                            id={`edit-${item.id}`}
                                                                            onClick={() => setForm({
                                                                                status: true,
                                                                                data: item
                                                                            })}>
                                                                            <i className="bx bx-pencil" />
                                                                        </Button>
                                                                        <UncontrolledTooltip target={`edit-${item.id}`}
                                                                            placement="bottom">
                                                                            Düzəliş et
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                </Can>
                                                                <Can action="cashbox_delete">
                                                                    <>
                                                                        <Button color="danger"
                                                                            id={`delete-${item.id}`}
                                                                            onClick={() => setConfirmModal(item.id)}>
                                                                            <i className="bx bx-trash" />
                                                                        </Button>
                                                                        <UncontrolledTooltip target={`delete-${item.id}`}
                                                                            placement="bottom">
                                                                            Sil
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                                </Can>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <Badge className="p-2 font-size-12" color="success">
                                                            {data?.reduce((acc, val) => {
                                                                return acc + (val?.price > 0 ? val.price : 0);
                                                            }, 0).toFixed(2)}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge className="p-2 font-size-12" color="danger">
                                                            {data?.reduce((acc, val) => {
                                                                return acc + (val?.price < 0 ? val.price : 0);
                                                            }, 0).toFixed(2)}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge className="p-2 font-size-12" color="primary">
                                                            Kassa qalıq : {data?.reduce((acc, val) => acc + val?.price, 0).toFixed(2)}
                                                        </Badge>
                                                    </td>
                                                    <td />
                                                    <td />

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <CustomPagination total={total} setPage={setPage} page={page} limit={PAGE_LIMIT} />
                                   
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Branches;
